import "./Pagination.css";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      {totalPages > 0 ? (
        <>
          <svg
            className="arrowButton"
            onClick={handlePrevClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3544 15.8527C12.1594 16.0484 11.8429 16.0489 11.6472 15.854L6.16276 10.389C5.94705 10.1741 5.94705 9.82477 6.16276 9.60982L11.6472 4.14484C11.8429 3.94993 12.1594 3.95049 12.3544 4.1461C12.5493 4.34171 12.5487 4.65829 12.3531 4.85321L7.18851 9.99942L12.3531 15.1456C12.5487 15.3406 12.5493 15.6571 12.3544 15.8527Z"
              fill="#424242"
            />
          </svg>
          <span className="page">{currentPage}</span>
          <span className="separator">of</span>
          <span className="pagesNumber">{totalPages}</span>

          <svg
            className="arrowButton"
            onClick={handleNextClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.64582 4.14708C7.84073 3.95147 8.15731 3.9509 8.35292 4.14582L13.8374 9.6108C14.0531 9.82574 14.0531 10.1751 13.8374 10.39L8.35292 15.855C8.15731 16.0499 7.84073 16.0493 7.64582 15.8537C7.4509 15.6581 7.45147 15.3415 7.64708 15.1466L12.8117 10.0004L7.64708 4.85418C7.45147 4.65927 7.4509 4.34269 7.64582 4.14708Z"
              fill="#424242"
            />
          </svg>
        </>
      ) : null}
    </div>
  );
};

export default Pagination;
