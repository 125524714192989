import { useTranslation } from "react-i18next";
import { Divider, Select, useId } from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";
import "./General.css";
import i18next from "i18next";
import { useState } from "react";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";
import Error from "../Error";
import helpers from "../../helpers/helpers";

export default function GeneralSettings() {
  const dropdownId = useId("dropdown-default");
  const { t } = useTranslation();
  const languageOptions = [
    { label: t("settings.english"), code: "en" },
    { label: t("settings.german"), code: "de" },
  ];
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const userStore = useStore(useUserStore);

  const changeLanguage: SelectProps["onChange"] = (event, data) => {
    setCurrentLanguage(data?.value);
    i18next.changeLanguage(data?.value);
    localStorage.setItem("selectedLanguage", data?.value);
  };

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Settings General",
        "Can Read"
      ) ? (
        <div className="generalSettingsPage">
          <div className="generalSettingsInfoContainer">
            <div className="title">{t("settings.general.title")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.general.description")}
              </span>
              <span className="details">App Version: 1.15.50</span>
            </div>
          </div>

          <Divider appearance="strong" />
          <div className="generalSettingsInfoContainer">
            <div className="title">{t("settings.general.appLanguage")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t(
                  "settings.general.hereSAnExampleOfHowASectionCanBeUsedToGroupInputs"
                )}
              </span>
            </div>
            <div className="settingsLanguageContainer">
              <label id={dropdownId} style={{ flexBasis: "50%" }}>
                {t("settings.language")}
              </label>
              <Select
                className="settingsSelectInput"
                appearance="filled-lighter"
                aria-labelledby={dropdownId}
                value={currentLanguage}
                onChange={changeLanguage}
                disabled={
                  !helpers.checkHasPermissions(
                    userStore?.user?.Permissions,
                    "Settings General",
                    "Can Edit"
                  )
                }
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
