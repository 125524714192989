import "./Step3.css";
import { Spinner } from "@fluentui/react-components";
import { Step3Table } from "../StepsTables/Step3Table";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import OrganizationService from "../../../../services/organization";
import { InitialRole } from "../../../../types";
import { useInitialSetupUsersStore } from "../../../../store/initialSetupUsersStore";
import { useStore } from "zustand";
import Support from "../../Support";

export function Step3(props: {
  setIsError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialRoles, setInitialRoles] = useState<InitialRole[]>([]);
  const initialUsersStore = useStore(useInitialSetupUsersStore);

  useEffect(() => {
    const getInitialUsers = async () => {
      setLoading(true);
      try {
        const result = await OrganizationService.getInitialUsers();
        setInitialRoles(result?.roles);
        if (initialUsersStore.users.length === 0) {
          initialUsersStore.addUsers(result?.users);
        }
      } catch (e) {
        props.setIsError(true);
        props.setErrorMessage(t("initialSetup.errors.errorGettingTheUsers"));
      } finally {
        setLoading(false);
      }
    };
    getInitialUsers();
  }, []);

  return (
    <div className="step3Container">
      <div className="step3HeaderContainer">
        <div className="step3HeaderTextSection">
          <div className="titleSection">
          <h2 className="step3Title">
              {t("initialSetup.steps.step3.step3Title")}
            </h2>
            <p className="step3Subtitle">
              {t("initialSetup.steps.step3.step3Subtitle")}
            </p>
            <p className="step3Subtitle">
              {t("initialSetup.steps.step3.step3Subtitle2")}
            </p>
          </div>
        </div>
        <Support />
      </div>
      <div>
        {loading ? (
          <Spinner style={{ margin: 100 }} />
        ) : (
          <Step3Table roles={initialRoles} />
        )}
      </div>
    </div>
  );
}
