import { useState, useEffect } from "react";
import "./Layout.css";
import SimpleNavbar from "../SimpleLayout/SimpleNavbar";
import SideNav from "./SideNav";
import { Outlet, useLocation } from "react-router-dom";
import { ChevronDoubleRightRegular } from "@fluentui/react-icons";
import { Image } from "@fluentui/react-components";
import { SearchContext } from "../../contexts/SearchContext";

export default function SettingsLayout() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sideNavOpen, setSideNavOpen] = useState(
    screenWidth <= 768 ? false : true
  );
  const location = useLocation();
  const [searchField, setSearchField] = useState<string>("");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (screenWidth > 768) {
      setSideNavOpen(true);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  useEffect(() => {
    setSearchField("");
  }, [location]);

  const closeMobileSideNav = () => {
    if (screenWidth < 768) {
      setSideNavOpen(false);
    }
  };

  return (
    <div className="settingsLayout">
      <SimpleNavbar searchField={searchField} setSearchField={setSearchField} />
      <div className="settingsContentLayout">
        <SideNav isOpen={sideNavOpen} closeMobileSideNav={closeMobileSideNav} />
        {screenWidth <= 768 ? (
          <>
            <div
              className={`toggleSideNavSettingsBtn ${
                sideNavOpen ? "hideToggleSidebar" : ""
              }`}
              onClick={() => setSideNavOpen(true)}
            >
              <ChevronDoubleRightRegular fontSize={24} />
            </div>
            <div
              className={`closeSidebarNav ${
                sideNavOpen ? "showSidenavClose" : ""
              }`}
              onClick={() => setSideNavOpen(false)}
            >
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/close.svg`}
                alt="close"
              />
            </div>
          </>
        ) : null}
        <div className="settingsMainContent">
          <SearchContext.Provider value={searchField}>
            <Outlet />
          </SearchContext.Provider>
        </div>
      </div>
    </div>
  );
}
